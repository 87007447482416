import window from 'global';
import * as React from 'react';
import ContactForm from '../components/ContactForm';
import Footer from '../components/Footer';
import Head from '../components/Head';
import MainSubtitle from '../components/MainSubtitle';
import MainTitle from '../components/MainTitle';
import MobileNavbar from '../components/MobileNav';
import Navbar from '../components/NavBar';
import Section from '../components/Section';
import SectionContentContainer from '../components/SectionContentContainer';
import corpTrainBgImg from '../images/corporate-training.webp';

const CorporatePage = () => {
  const breakpoint = 991;
  const [dimension, setDimension] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  React.useEffect(() => {
    function handleResize() {
      setDimension({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }
    window.addEventListener('resize', handleResize);
  }, []);
  return (
    <>
      <Head pageTitle="Corporate Training" />
      {dimension.width > breakpoint ? <Navbar /> : <MobileNavbar />}
      <Section backgroundImage={corpTrainBgImg} customStyle={{ textAlign: 'center' }}>
        {dimension.width > breakpoint ? (
          <SectionContentContainer customStyle={{ left: 0 }}>
            <MainTitle text="CORPORATE TRAINING" style={{ fontSize: 'calc(3vw + 3vh)' }} />
            <br />
            <MainSubtitle text="WE PROVIDE TRAINING FOR EXCEL VBA, PYTHON, JAVA AND TECH-DUCATION" style={{ fontSize: 'calc(1.2vw + 1.2vh)' }} />
          </SectionContentContainer>
        ) : (
          <SectionContentContainer customStyle={{ left: 0 }}>
            <MainTitle text="CORPORATE TRAINING" style={{ fontSize: 'calc(4vw + 4vh)' }} />
            <br />
            <MainSubtitle text="WE PROVIDE TRAINING FOR EXCEL VBA, PYTHON, JAVA AND TECH-DUCATION" style={{ fontSize: 'calc(2vw + 2vh)' }} />
          </SectionContentContainer>
        )}
      </Section>
      {dimension.width > breakpoint ? (
        <Section customStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.95)', textAlign: 'center' }}>
          <SectionContentContainer customStyle={{
            position: 'relative', left: 0, padding: '5% 35%',
          }}
          >
            <ContactForm formTitle="CONTACT US" />
          </SectionContentContainer>
        </Section>
      ) : (
        <Section customStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.95)', textAlign: 'center' }}>
          <SectionContentContainer customStyle={{
            position: 'relative', left: 0, padding: '5%',
          }}
          >
            <ContactForm formTitle="CONTACT US" />
          </SectionContentContainer>
        </Section>
      )}
      <Footer />
    </>
  );
};

export default CorporatePage;
